<template>
  <div>
    <Breadcrumbs :items="crumbs" />
    <section>
      <v-container>
        <v-layout>
          <v-flex xs12 sm6 md4 pt-5>
            <div class="display-1">Let's Connect</div>
            <v-divider class="my-2"></v-divider>
            <div class="pt-0 pb-5">
            <v-btn class="mr-3 ml-0" icon href="https://www.facebook.com/TechCentrixUSA/" target="_blank"><v-icon>fa-facebook</v-icon></v-btn>
            <v-btn class="mr-3" icon href="https://twitter.com/TechCentrixUSA" target="_blank"><v-icon>fa-twitter</v-icon></v-btn>
            <v-btn class="mr-3" icon href="https://www.instagram.com/TechCentrix/" target="_blank"><v-icon>fa-instagram</v-icon></v-btn>
            <v-btn class="mr-3" icon href="https://www.instagram.com/TechCentrix/" target="_blank"><v-icon>fab fa-linkedin-in</v-icon></v-btn>
            </div>
            <div class="display-1">Contact Details</div>
            <v-divider class="my-2"></v-divider>
            <div class="pt-0">
            <div class="mb-2"><i class="fas fa-phone"></i> <a class="nounder black--text ml-3" href="tel:925-425-9282">925-425-9282</a></div>
            <div class="mb-2"><i class="fas fa-envelope"></i> <a class="nounder black--text ml-3" href="mailto:info@techcentrix.com">info@techcentrix.com</a></div>
           
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </section>
  </div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
  data() {
    return {
      name: this.$route.name,
      crumbs: [
        {
          text: 'Home',
          disabled: false,
          exact: true,
          to: '/'
        },
        {
          text: 'Connect',
          disabled: true,
          to: ''
        }
      ],
    }
  },
  components: {
    Nav,
    Breadcrumbs,
    Footer
  },
}
</script>